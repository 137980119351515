import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal, Table, Tooltip, Typography } from "antd";
import { onShowSizeChange, itemRender } from "../../Pagination";
import { FaTrashRestoreAlt } from "react-icons/fa";
import { getTashAdmins, RestoreAdmin } from "../../../api/AdminRequest";
import ErrorLogout from "../../../helper/ErrorLogout";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const moment = require("moment");
const { Text } = Typography;
const AdminTrashList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [loader, setLoader] = useState(true);
  const [trigger, setTrigger] = useState();
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const viewTrashAdmins = async () => {
      try {
        const { data } = await getTashAdmins();
        setAdmins(data.admins);
        setLoader(false);
      } catch (error) {
        console.log(error.message);
        if (error.response.status === 403) {
          ErrorLogout(error);
        }
      }
    };
    viewTrashAdmins();
  }, [trigger]);

  const handleRestore = async (id) => {
    confirm({
      title: "Do you Want to Restore the Admin",
      icon: <ExclamationCircleFilled />,
      content:
        "if you Restore the Admin it will activate all the Service under this Admin.",
      onOk() {
        const restoreCall = async () => {
          try {
            const { data } = await RestoreAdmin(id._id);
            if (data.restore) {
              setTrigger(Date.now());
            }
          } catch (error) {
            console.log(error.message);
          }
        };
        restoreCall();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const column = [
    {
      title: "Sl.No",
      dataIndex: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
    
    },
    {
      title: "Phone",
      dataIndex: "phone",
    
    },
    {
      title: "Service Limit",
      dataIndex: "serviceLimit",
      sorter: (a, b) => a.serviceLimit - b.serviceLimit,
    },

    {
      title: "Expire date",
      dataIndex: "expireDate",
      key: "expire",
      render: (expireDate) => {
        let expiration = moment(expireDate).format("YYYY-MM-DD");
        let current_date = moment().format("YYYY-MM-DD");
        let days = moment(expiration).diff(current_date, "days");
        return (
          <Text
            type={`${days > 10 ? "success" : days > 5 ? "warning" : "danger"}`}
          >
            {moment(expireDate).format("DD-MM-YYYY")}
          </Text>
        );
      },
      // sorter: (a, b) => new Date(a.expireDate) - new Date(b.expireDate),
      
    },
    {
      title: "Action",
      dataIndex: "status._id",
      render: (status, _id) => (
        <>
          <div className="actions">
            <Tooltip title="restore admin">
              <FaTrashRestoreAlt size={20} onClick={() => handleRestore(_id)} />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h3 className="page-title">Admin Trash</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/superAdmin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Admin Trash</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}

      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-body">
              <Table
                class="table table-stripped table-hover datatable"
                pagination={{
                  total: admins.length,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  onShowSizeChange: onShowSizeChange,
                  itemRender: itemRender,
                }}
                columns={column}
                loading={loader}
                dataSource={admins}
                // rowSelection={rowSelection}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTrashList;
