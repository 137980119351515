import React, { useState } from "react";
// import DatePicker from 'react-datepicker';
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, InputNumber, DatePicker, Spin } from "antd";
import { addAdmin, editAdmin } from "../../../api/AdminRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
const AddAdmin = ({ editData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (adminData) => {
    try {
      setLoading(true);
      const { data } = editData
        ? await editAdmin(editData?._id, adminData)
        : await addAdmin(adminData);
      if (data?.success) {
        setLoading(false);
        toast.success("Admin Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/superAdmin/adminList");
        }, 2500);
      } else if (data?.update) {
        setLoading(false);
        toast.success("Admin Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          navigate("/superAdmin/adminList");
        }, 2500);
      }
    } catch (error) {
      console.log("error");
      setLoading(false);
      toast(error.message);
      if (error.response?.data?.message) {
        toast(error?.response?.data?.message);
      }
    }
  };
  return (
    <Spin spinning={loading}>
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">
                {editData ? "Edit Admin" : "Add Admin"}
              </h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/department">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  {editData ? "Edit Admin" : "Add Admin"}
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <Form
                  className="relative"
                  onFinish={handleSubmit}
                  initialValues={{
                    name: editData?.name,
                    email: editData?.email,
                    phone: editData?.phone,

                    serviceLimit: editData?.serviceLimit,
                    expireDate: moment(editData?.expireDate),
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <label>
                        Name <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please Enter admin Name!",
                          },
                        ]}
                      >
                        <Input className="h-10" placeholder="Admin Name" />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-sm-4">
                      <label>
                        Email <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please Enter your E-mail!",
                          },
                        ]}
                      >
                        <Input className="h-10" placeholder="Official Email" />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-sm-4">
                      <label>
                        Phone <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            type: "phone",
                            message: "The input is not valid phone number!",
                          },
                          {
                            required: true,
                            message: "Please Enter admin phone number!",
                          },
                        ]}
                      >
                        <Input
                          className="h-10"
                          placeholder="Admin Phone number"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-sm-4">
                      <label>
                        Service Limit <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="serviceLimit"
                        rules={[
                          {
                            type: "number",
                            message: "The input is not valid number!",
                          },
                          {
                            required: true,
                            message: "Please Enter admin service limit!",
                          },
                        ]}
                      >
                        <InputNumber
                          className="h-10 w-[320px]"
                          placeholder="Admin service limit"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-sm-4">
                      <label>
                        Expire Date <span className="login-danger">*</span>
                      </label>
                      <Form.Item
                        name="expireDate"
                        rules={[
                          {
                            type: "date",
                            message: "The input is not valid date !",
                          },
                          {
                            required: true,
                            message: "Please Enter admin expire date",
                          },
                        ]}
                      >
                        <DatePicker
                          className="h-10 w-[320px]"
                          placeholder="Admin expire date"
                        />
                      </Form.Item>
                    </div>
                    {editData ? (
                      ""
                    ) : (
                      <>
                        <div className="col-12 col-sm-4 relative">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter admin password!",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.Password
                              className="h-10 "
                              placeholder="Password*"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-sm-4">
                          <label>
                            Confirm Password{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <Form.Item
                            name="confirm_password"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please Enter admin confirm password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "The new password that you entered do not match!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              className="h-10 "
                              placeholder="Confirm Password*"
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                    <div className="col-12 col-sm-4 mt-8">
                      <Button
                        className="w-full h-10 rounded-md text-white bg-sky-700"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </div>
                    <div className="form-group mb-0"></div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Spin>
  );
};

export default AddAdmin;
