import React from "react";
import AddAdmin from "../../components/superAdmin/admins/AddAdmin";
import Header from "../../components/header/Header";
import Sidebar from "../../components/superAdmin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import { useLocation } from "react-router-dom";
function EditAdminPage() {
  const location = useLocation();
  const editData = location.state?.editData;
  return (
    <div className="main-wrapper">
      <Header sprAdmin={true} />

      <Sidebar />
      <div className="page-wrapper">
        <AddAdmin editData={editData} />
        <Footer />
      </div>
    </div>
  );
}

export default EditAdminPage;
