import React from 'react'
const Footer = () => {
    return (
        <>
            <footer>
                <p>Copyright © 2023 Metasoft IT Solutions.</p>
            </footer>
        </>
    )
}

export default Footer
