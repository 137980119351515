import React from "react";
import AddAdmin from "../../components/superAdmin/admins/AddAdmin";
import Header from "../../components/header/Header";
import Sidebar from "../../components/superAdmin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
function AddAdminPage() {
  return (
    <div className="main-wrapper">
      <Header sprAdmin={true} />

      <Sidebar />
      <div className="page-wrapper">
        <AddAdmin />
        <Footer />
      </div>
    </div>
  );
}

export default AddAdminPage;
