export { default as favicon} from '../assets/img/favicon.png'
export { default as login } from "../assets/img/login.png";

export {default as logo} from '../assets/img/logo.png';
export {default as logosmall} from '../assets/img/logo-small.png';

export {default as headericon01} from '../assets/img/icons/header-icon-01.svg';
export {default as headericon05} from '../assets/img/icons/header-icon-05.svg';
export {default as headericon04} from '../assets/img/icons/header-icon-04.svg';

export {default as lr} from '../assets/plugins/icons/flags/flags/lr.png';
export {default as bl} from '../assets/plugins/icons/flags/flags/bl.png';
export {default as cn} from '../assets/plugins/icons/flags/flags/cn.png';

export {default as blog1} from '../assets/img/category/blog-1.jpg';
export {default as blog2} from '../assets/img/category/blog-2.jpg';
export {default as blog3} from '../assets/img/category/blog-3.jpg';
export {default as blog4} from '../assets/img/category/blog-4.jpg';
export {default as blog5} from '../assets/img/category/blog-5.jpg';
export {default as blog6} from '../assets/img/category/blog-6.jpg';
export {default as blogdetail} from '../assets/img/category/blog-detail.jpg';


export {default as profilebg} from '../assets/img/profile-bg.jpg';
export {default as profileuser} from '../assets/img/profile-user.jpg';

export {default as img1} from '../assets/img/profiles/avatar-01.jpg';
export {default as img2} from '../assets/img/profiles/avatar-02.jpg';
export {default as img3} from '../assets/img/profiles/avatar-03.jpg';
export {default as img4} from '../assets/img/profiles/avatar-04.jpg';
export {default as img5} from '../assets/img/profiles/avatar-05.jpg';
export {default as img6} from '../assets/img/profiles/avatar-06.jpg';
export {default as img7} from '../assets/img/profiles/avatar-07.jpg';
export {default as img8} from '../assets/img/profiles/avatar-08.jpg';
export {default as img9} from '../assets/img/profiles/avatar-09.jpg';
export {default as img10} from '../assets/img/profiles/avatar-10.jpg';


export {default as avatar01} from '../assets/img/profiles/avatar-01.jpg';
export {default as avatar02} from '../assets/img/profiles/avatar-02.jpg';
export {default as avatar03} from '../assets/img/profiles/avatar-03.jpg';
export {default as avatar04} from '../assets/img/profiles/avatar-04.jpg';
export {default as avatar05} from '../assets/img/profiles/avatar-05.jpg';
export {default as avatar06} from '../assets/img/profiles/avatar-06.jpg';
export {default as avatar07} from '../assets/img/profiles/avatar-07.jpg';
export {default as avatar08} from '../assets/img/profiles/avatar-08.jpg';
export {default as avatar09} from '../assets/img/profiles/avatar-09.jpg';
export {default as avatar10} from '../assets/img/profiles/avatar-10.jpg';
export {default as avatar11} from '../assets/img/profiles/avatar-11.jpg';
export {default as avatar12} from '../assets/img/profiles/avatar-12.jpg';
export {default as avatar13} from '../assets/img/profiles/avatar-13.jpg';
export {default as avatar14} from '../assets/img/profiles/avatar-14.jpg';
export {default as avatar17} from '../assets/img/profiles/avatar-17.jpg';
export {default as avatar18} from '../assets/img/profiles/avatar-18.jpg';


export {default as invoicecircle1} from '../assets/img/invoice-circle1.png';
export {default as invoicecircle2} from '../assets/img/invoice-circle2.png';


export {default as signature} from '../assets/img/signature.png';


export {default as invoicesicon1} from '../assets/img/icons/invoices-icon1.svg';
export {default as invoicesicon2} from '../assets/img/icons/invoices-icon2.svg';
export {default as invoicesicon3} from '../assets/img/icons/invoices-icon3.svg';
export {default as invoicesicon4} from '../assets/img/icons/invoices-icon4.svg';
export {default as invoicesicon5} from '../assets/img/icons/invoices-icon5.png';
export {default as dashicon01} from '../assets/img/icons/dash-icon-01.svg';
export {default as dashicon02} from '../assets/img/icons/dash-icon-02.svg';
export {default as dashicon03} from '../assets/img/icons/dash-icon-03.svg';
export {default as dashicon04} from '../assets/img/icons/dash-icon-04.svg';
export {default as awardicon01} from '../assets/img/icons/award-icon-01.svg';
export {default as awardicon02} from '../assets/img/icons/award-icon-02.svg';
export {default as awardicon03} from '../assets/img/icons/award-icon-03.svg';
export {default as awardicon04} from '../assets/img/icons/award-icon-04.svg';
export {default as socialicon01} from '../assets/img/icons/social-icon-01.svg';
export {default as socialicon02} from '../assets/img/icons/social-icon-02.svg';
export {default as socialicon03} from '../assets/img/icons/social-icon-03.svg';
export {default as socialicon04} from '../assets/img/icons/social-icon-04.svg';
export {default as teachericon01} from '../assets/img/icons/teacher-icon-01.svg';
export {default as teachericon02} from '../assets/img/icons/teacher-icon-02.svg';
export {default as teachericon03} from '../assets/img/icons/teacher-icon-03.svg';
export {default as studenticon01} from '../assets/img/icons/student-icon-01.svg';
export {default as studenticon02} from '../assets/img/icons/student-icon-02.svg';
export {default as lessonicon01} from '../assets/img/icons/lesson-icon-01.svg';
export {default as lessonicon02} from '../assets/img/icons/lesson-icon-02.svg';
export {default as lessonicon03} from '../assets/img/icons/lesson-icon-03.svg';
export {default as lessonicon04} from '../assets/img/icons/lesson-icon-04.svg';
export {default as lessonicon05} from '../assets/img/icons/lesson-icon-05.svg';
export {default as lessonicon06} from '../assets/img/icons/lesson-icon-06.svg';
export {default as bulidingicon} from '../assets/img/icons/buliding-icon.svg';



export {default as img01} from '../assets/img/img-01.jpg';
export {default as img02} from '../assets/img/img-02.jpg';
export {default as img03} from '../assets/img/img-03.jpg';
export {default as img04} from '../assets/img/img-04.jpg';
export {default as img05} from '../assets/img/img-05.jpg';

export {default as imgg1} from '../assets/img/img-1.jpg';
export {default as imgg2} from '../assets/img/img-2.jpg';
export {default as imgg3} from '../assets/img/img-3.jpg';
export {default as imgg4} from '../assets/img/img-4.jpg';
export {default as imgg5} from '../assets/img/img-5.jpg';


export {default as user5} from '../assets/img/user-5.jpg';
export {default as user6} from '../assets/img/user-6.jpg';
export {default as user7} from '../assets/img/user-7.jpg';


