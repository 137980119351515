import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import "./assets/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/css/style.css";

import "./assets/plugins/bootstrap/js/bootstrap.bundle.min.js";
import "./assets/plugins/select2/css/select2.min.css";
import './index.css'

//Font Awesome
// import "./assets/plugins/fontawesome/css/fontawesome.min.css";
// import "./assets/plugins/fontawesome/css/all.min.css";


const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)