import React from 'react'
import Header from "../../components/header/Header";
import Sidebar from "../../components/superAdmin/sidebar/SideBar";
import Footer from "../../components/footer/Footer";
import AdminTrashList from '../../components/superAdmin/admins/AdminTrashList';
function AdminTrashPage() {
  return (
    <div className="main-wrapper">
    <Header sprAdmin={true} />

    <Sidebar />
    <div className="page-wrapper">
      <AdminTrashList />
      <Footer />
    </div>
  </div>
  )
}

export default AdminTrashPage