// import React from 'react'

import Swal from "sweetalert2"


function ErrorLogout(error) {
    Swal.fire({
        title: error.response.data.message,
        text: "Please Contact Admin and login again...",
    }).then(() => {
        // localStorage.removeItem('refToken')
        // localStorage.removeItem('admin-refToken')
        localStorage.clear()
        window.location.href = '/'
    })
    //   return (
    //     <div>

    //     </div>
    //   )
}

export default ErrorLogout